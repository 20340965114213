body {
  --lineAccentWidth: 85%;
}

.InfoContainer {
  position: relative;
  height: 81svh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InfoContainer::before {
  content: '';
  /* border-top: solid 4px #f90; */
  background-color: var(--accentColor);
  position: absolute;
  top: 0;
  width: var(--lineAccentWidth);
  box-sizing: border-box;
  padding: 1.75px;
  /* margin-inline: 5vw; */
  /* border-radius: 2px; */
}

#infoWrapper {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--backgroundColor);
  outline: var(--outline);
  box-shadow: inset 0 0 20px var(--backgroundColor2),
    0 0 40px rgba(0, 0, 0, 0.075);
  border-radius: 20px;
}

#HoverClass {
  height: 100%;
  aspect-ratio: 3.5/4;
  position: relative;
  z-index: 6;
  display: grid;
  place-items: center;
  translate: 0 -4%;
}

#HoverClass::before {
  content: '';
  height: 112.5%;
  width: 125%;

  /* aspect-ratio: 3.5/4; */
  position: absolute;
  /* z-index: 6; */
  background-color: var(--backgroundColor);
  border: var(--outline);
  border-inline: 2px solid color-mix(in srgb, var(--outlineColor) 70%, wheat);
  box-shadow: inset 0 0 20px var(--backgroundColor2),
    0 0 40px var(--backgroundColor2);
  border-radius: 50px;
  /* transition: 1s; */

  /* animation:  Pulsing 2.5s ease infinite; */

  /* padding: 0px; */
  /* border-inline: var(--outline); */
}

#welcomeText {
  position: absolute;
  font-family: var(--secondaryFont);
  color: var(--mainFontColor);
  font-size: 2rem;
  font-weight: 300;
  /* font-style: italic; */
  top: 2.5%;
  text-shadow: 0 0 20px var(--shadowGlow2High);
}

#ProfileImage {
  position: relative;
  z-index: 2;
  width: 37.5svh;
  height: 37.5svh;
  transition: 0.4s ease;
  pointer-events: all;
  /* filter: hue-rotate(-20deg); */
  animation: Bobbing 2.5s ease-in-out infinite alternate;
  border-radius: 50%;
  /* outline: var(--accentColor) solid 3px; */
  outline: var(--outlineGlow);
  box-shadow: 0 0 30px var(--shadowGlow2);
  background-image: url(images/Hover.png), var(--pfp);
  background-size: cover;
  translate: var(--y);
  cursor: url(./images/link.png), pointer;
}

#ProfileImage:hover {
  background-image: url(images/ClickME.png), var(--pfp);
  /* border-radius: 45%; */
  animation-play-state: paused;
  scale: 1.02;
}

#ProfileImage img {
  object-fit: contain;
}

.NameClass {
  position: absolute;
  /* width: 100%; */
  color: var(--mainFontColor);
  font-family: var(--secondaryFont);
  font-size: 2.5rem;
  transition: 0.2s ease-in-out;
  bottom: 2.5%;
  text-shadow: 0 0 20px var(--shadowGlow2High);
  /* text-shadow: 0 0 20px var(--shadowGlow); */
  translate: -0.1em 0;
}

.textClass {
  width: calc(100% / 3.7);
  text-transform: uppercase;
  font-size: 7.6rem;
  color: var(--mainFontColor);
  /* opacity: 0.9; */
  --shadow: rgb(50, 75, 90);
  line-height: 0;
  text-shadow: 0 0 60px var(--shadowGlow2High);
  transition: 0.5s ease;
}

#GameDevBgText {
  transform: translateY(0.05em);
  font-size: 9.5rem;
}

#DigitalArtistBgText:hover {
  text-shadow: -8px 0px 0px var(--shadow);
  translate: 8px 0px;
  opacity: 1;
}

#GameDevBgText:hover {
  text-shadow: 4px 0px 0px var(--shadow), 8px 0px 0px var(--shadow);
  translate: -8px 0px;
  opacity: 1;
}

#AboutMeWrap {
  border-radius: 30px;
  transition: 0.2s ease-out 1s;
  width: var(--lineAccentWidth);
  box-sizing: border-box;
  padding: 1.75px;
}

.AboutMe {
  display: flex;
  justify-content: center;
  color: whitesmoke;
}

.AboutMe.CurrentItem .AboutBody {
  grid-template-rows: 0fr;
}

.AboutMe .AboutBody {
  transition: 1000ms grid-template-rows ease;
  display: grid;
  grid-template-rows: 1fr;
  border-radius: 29px;
}

.AboutMe #AboutMeWrap {
  background-color: var(--outlineGlow2);
}

.AboutMe.CurrentItem #AboutMeWrap {
  background-color: var(--accentColor);
  /* box-shadow: 0 0 40px transparent; */
}

.AboutMeText {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background-size: cover;
  background-position: center;
  padding-bottom: 3em;
  padding-inline: 10em;
  transition: padding 0.5s;
  overflow: hidden;
}

.AboutMe.CurrentItem .AboutMeText {
  padding-block: 0;
}

#AboutMeHeading {
  font-family: var(--secondaryFont);
  font-size: 3rem;
  font-weight: 100;
  text-wrap: nowrap;
  /* overflow-x: hidden; */
  margin: 0;
  translate: 0 40%;
  border: color-mix(in srgb, var(--outlineColor) 70%, wheat) 2px solid;
  border-top: color-mix(in srgb, var(--outlineColor) 20%, wheat) 2px solid;
  border-bottom: var(--outlineColor) 2px solid;
  box-shadow: inset 0 0 20px var(--backgroundColor2),
    0 0 40px var(--backgroundColor2) 2px solid;
}

#AboutMeBody {
  /* overflow: visible; */
  padding-inline: 1.3em;
  padding-block: 0;
  padding-top: 1em;
  border-radius: 30px;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 300;
  font-size: 1.1em;
  /* -webkit-font-size: 1.1em; */
  /* width: 100%; */
  /* height: fit-content; */
  background-color: var(--backgroundColor2);
  outline: color-mix(in srgb, var(--outlineColor) 60%, wheat) 2px solid;
  box-shadow: inset 0 0 40px var(--shadowGlow2);
  /* backdrop-filter: blur(20px) grayscale(0.5); */

  /* text-align: justify;
  text-justify: inter-word; */
  /* overflow: hidden; */
}

#AboutHere {
  /* background-image: url(images/Crystal\ Scene\ better.webp); */
  background-color: var(--backgroundColor2);
  box-shadow: 0 0 40px var(--shadowGlow2), inset 0 0 40px var(--shadowGlow2High);
  background-size: cover;
  background-position: center;
}

.ContactMe {
  background-color: var(--backgroundColor);
  box-shadow: inset 0px 0px 20px var(--backgroundColor2);
  position: relative;
  /* width: 100%; */
  height: 50vh;
  /* overflow: hidden; */
  z-index: 0;
  outline: var(--outline);
  border-radius: 30px;
}

#FormCenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.FeedbackText {
  font-size: 22vw;
  color: inherit;
  opacity: 0.04;
  position: absolute;
  align-content: center;
  width: 100%;
  height: 100%;
  z-index: 0;
}
#FormStatus {
  font-family: 'Noto sans', sans-serif;
  font-size: 1rem;
  opacity: 0.5;
  margin-bottom: 1.5%;
}
#FormFill {
  font-family: 'Noto Sans', sans-serif;
  color: var(--mainFontColor);
  font-weight: 600;
  font-size: 1.1rem;
  resize: none;
  display: block;
  border-radius: 10px;
  padding: 1em;
  padding-top: 0.7em;
  border: var(--outline);
  outline: none;
  margin-top: 20%;
  width: 40vw;
  height: 20vh;
  background-color: var(--backgroundColor2);
  transition: 0.2s ease;
}

#FormFill:focus {
  box-shadow: 0 0 80px var(--shadowGlow);
  --glow: var(--shadowGlow);
  animation: flickerLight 0.2s forwards;
  border: var(--outlineGlow);
  position: relative;
  z-index: 2;
}

#FormButton {
  background-color: var(--backgroundColor2);
  border: var(--outlineGlow);
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 0px 30px var(--shadowGlow2High),
    inset 0px 0px 30px var(--shadowGlow2);
  color: inherit;
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 1.2rem;
  transition: 0.1s ease;
  transition: 0.2s ease;
  border-radius: 10px;
  padding-top: 2px;
  cursor: url(./images/link.png), pointer;
  padding: 0.75em;
  padding-inline: 1.5em;
}

#FormButton:hover {
  color: black;
  /* cursor: pointer; */
  background-color: var(--accentColor);
  --thicknessColor: white;
  box-shadow: 0px 6px 30px rgba(255, 0, 0, 0.6);
  /* translate: 0 6px; */
}

#FormButton:disabled {
  color: var(--outlineColor);
  background-color: var(--backgroundColor2);
  border: var(--outline);
  box-shadow: 0 0 0;
  translate: 0 0;
}
