body {
  --lineAccentWidth: 85%;
}

.InfoContainer {
  justify-content: center;
  align-items: center;
  height: 81svh;
  display: flex;
  position: relative;
}

.InfoContainer:before {
  content: "";
  background-color: var(--accentColor);
  width: var(--lineAccentWidth);
  box-sizing: border-box;
  padding: 1.75px;
  position: absolute;
  top: 0;
}

#infoWrapper {
  background-color: var(--backgroundColor);
  outline: var(--outline);
  box-shadow: inset 0 0 20px var(--backgroundColor2), 0 0 40px #00000013;
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70%;
  display: flex;
}

#HoverClass {
  aspect-ratio: 3.5 / 4;
  z-index: 6;
  place-items: center;
  height: 100%;
  display: grid;
  position: relative;
  translate: 0 -4%;
}

#HoverClass:before {
  content: "";
  background-color: var(--backgroundColor);
  border: var(--outline);
  border-inline: 2px solid color-mix(in srgb, var(--outlineColor) 70%, wheat);
  box-shadow: inset 0 0 20px var(--backgroundColor2), 0 0 40px var(--backgroundColor2);
  border-radius: 50px;
  width: 125%;
  height: 112.5%;
  position: absolute;
}

#welcomeText {
  font-family: var(--secondaryFont);
  color: var(--mainFontColor);
  text-shadow: 0 0 20px var(--shadowGlow2High);
  font-size: 2rem;
  font-weight: 300;
  position: absolute;
  top: 2.5%;
}

#ProfileImage {
  z-index: 2;
  pointer-events: all;
  outline: var(--outlineGlow);
  box-shadow: 0 0 30px var(--shadowGlow2);
  background-image: url("Hover.477d8159.png"), var(--pfp);
  translate: var(--y);
  cursor: url("link.6ebb9d0c.png"), pointer;
  background-size: cover;
  border-radius: 50%;
  width: 37.5svh;
  height: 37.5svh;
  transition: all .4s;
  animation: 2.5s ease-in-out infinite alternate Bobbing;
  position: relative;
}

#ProfileImage:hover {
  background-image: url("ClickME.357a71a9.png"), var(--pfp);
  animation-play-state: paused;
  scale: 1.02;
}

#ProfileImage img {
  object-fit: contain;
}

.NameClass {
  color: var(--mainFontColor);
  font-family: var(--secondaryFont);
  text-shadow: 0 0 20px var(--shadowGlow2High);
  font-size: 2.5rem;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 2.5%;
  translate: -.1em;
}

.textClass {
  text-transform: uppercase;
  color: var(--mainFontColor);
  --shadow: #324b5a;
  text-shadow: 0 0 60px var(--shadowGlow2High);
  width: 27.027%;
  font-size: 7.6rem;
  line-height: 0;
  transition: all .5s;
}

#GameDevBgText {
  font-size: 9.5rem;
  transform: translateY(.05em);
}

#DigitalArtistBgText:hover {
  text-shadow: -8px 0px 0px var(--shadow);
  opacity: 1;
  translate: 8px;
}

#GameDevBgText:hover {
  text-shadow: 4px 0px 0px var(--shadow), 8px 0px 0px var(--shadow);
  opacity: 1;
  translate: -8px;
}

#AboutMeWrap {
  width: var(--lineAccentWidth);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 1.75px;
  transition: all .2s ease-out 1s;
}

.AboutMe {
  color: #f5f5f5;
  justify-content: center;
  display: flex;
}

.AboutMe.CurrentItem .AboutBody {
  grid-template-rows: 0fr;
}

.AboutMe .AboutBody {
  border-radius: 29px;
  grid-template-rows: 1fr;
  transition: grid-template-rows 1s;
  display: grid;
}

.AboutMe #AboutMeWrap {
  background-color: var(--outlineGlow2);
}

.AboutMe.CurrentItem #AboutMeWrap {
  background-color: var(--accentColor);
}

.AboutMeText {
  text-align: left;
  padding-bottom: 3em;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  padding-inline: 10em;
  transition: padding .5s;
  display: flex;
  overflow: hidden;
}

.AboutMe.CurrentItem .AboutMeText {
  padding-block: 0;
}

#AboutMeHeading {
  font-family: var(--secondaryFont);
  text-wrap: nowrap;
  border: color-mix(in srgb, var(--outlineColor) 70%, wheat) 2px solid;
  border-top: color-mix(in srgb, var(--outlineColor) 20%, wheat) 2px solid;
  border-bottom: var(--outlineColor) 2px solid;
  box-shadow: inset 0 0 20px var(--backgroundColor2), 0 0 40px var(--backgroundColor2) 2px solid;
  margin: 0;
  font-size: 3rem;
  font-weight: 100;
  translate: 0 40%;
}

#AboutMeBody {
  background-color: var(--backgroundColor2);
  outline: color-mix(in srgb, var(--outlineColor) 60%, wheat) 2px solid;
  box-shadow: inset 0 0 40px var(--shadowGlow2);
  padding-block: 0;
  padding-inline: 1.3em;
  border-radius: 30px;
  padding-top: 1em;
  font-family: Noto Sans, sans-serif;
  font-size: 1.1em;
  font-weight: 300;
}

#AboutHere {
  background-color: var(--backgroundColor2);
  box-shadow: 0 0 40px var(--shadowGlow2), inset 0 0 40px var(--shadowGlow2High);
  background-position: center;
  background-size: cover;
}

.ContactMe {
  background-color: var(--backgroundColor);
  box-shadow: inset 0px 0px 20px var(--backgroundColor2);
  z-index: 0;
  outline: var(--outline);
  border-radius: 30px;
  height: 50vh;
  position: relative;
}

#FormCenter {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.FeedbackText {
  color: inherit;
  opacity: .04;
  z-index: 0;
  align-content: center;
  width: 100%;
  height: 100%;
  font-size: 22vw;
  position: absolute;
}

#FormStatus {
  opacity: .5;
  margin-bottom: 1.5%;
  font-family: Noto sans, sans-serif;
  font-size: 1rem;
}

#FormFill {
  color: var(--mainFontColor);
  resize: none;
  border: var(--outline);
  background-color: var(--backgroundColor2);
  border-radius: 10px;
  outline: none;
  width: 40vw;
  height: 20vh;
  margin-top: 20%;
  padding: .7em 1em 1em;
  font-family: Noto Sans, sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .2s;
  display: block;
}

#FormFill:focus {
  box-shadow: 0 0 80px var(--shadowGlow);
  --glow: var(--shadowGlow);
  border: var(--outlineGlow);
  z-index: 2;
  animation: .2s forwards flickerLight;
  position: relative;
}

#FormButton {
  background-color: var(--backgroundColor2);
  border: var(--outlineGlow);
  box-shadow: 0px 0px 30px var(--shadowGlow2High), inset 0px 0px 30px var(--shadowGlow2);
  color: inherit;
  cursor: url("link.6ebb9d0c.png"), pointer;
  padding: .75em;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  padding-inline: 1.5em;
  font-family: Noto Sans;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all .2s;
}

#FormButton:hover {
  color: #000;
  background-color: var(--accentColor);
  --thicknessColor: white;
  box-shadow: 0 6px 30px #f009;
}

#FormButton:disabled {
  color: var(--outlineColor);
  background-color: var(--backgroundColor2);
  border: var(--outline);
  translate: 0;
  box-shadow: 0 0;
}
/*# sourceMappingURL=index.076987a3.css.map */
